/* global grecaptcha:readonly */

import axios from 'axios';
import eachElement from 'Utils/eachElement.js';
import errorHandler from 'Utils/errorHandler.js';
import recaptchaKey from 'Data/recaptchaKey.js';

const enableSubmit = (form, enabled) => {
	form.querySelector('button[type="submit"]').disabled = !enabled;
};

const setUpFormResponse = callback => {
	if (document.querySelector('#form-toggler')) {
		document.querySelector('[data-form-toggle]')?.addEventListener('click', event => {
			event.preventDefault();
			const toggler = event.target.closest('#form-toggler');
			toggler.classList.add('is-active');

			setTimeout(() => {
				toggler.querySelector('form input').focus();
			}, 200);

			return false;
		});

		document.querySelector('[data-form-cancel]')?.addEventListener('click', event => {
			event.preventDefault();
			event.target.closest('#form-toggler').classList.remove('is-active');
			return false;
		});
	}

	eachElement('form.managed-response', element => {
		element.addEventListener('submit', event => {
			event.preventDefault();
			grecaptcha.ready(() => {
				grecaptcha.execute(recaptchaKey, { action: 'submit' }).then(token => {
					const form = event.target;
					enableSubmit(form, false);

					const formTarget = form.dataset.formPostUrl || form.getAttribute('action');
					const formData = new FormData(form);
					formData.append('recaptcha', token);

					/* If we have a callback, execute that */
					if (callback) {
						axios.post(formTarget, formData).catch(errorHandler).finally(callback);
					} else if (form.parentNode.querySelectorAll('.notifications').length > 0) {
						/* If we have a thank you card, show that */
						axios.post(formTarget, formData).then(() => {
							form.style.display = 'none';
							form.parentNode.querySelector('.notification.is-red').classList.add('is-hidden');

							const banner = form.parentNode.querySelector('.notification.is-green');
							banner.style.display = '';
							banner.classList.remove('is-hidden');

							if (banner.textContent === '') {
								banner.textContent = 'Thank you for signing up!';
							}
						}).catch(error => {
							const banner = form.parentNode.querySelector('.notification.is-red');
							banner.style.display = '';
							banner.classList.remove('is-hidden');

							if (banner.textContent === '') {
								banner.textContent = 'Something went wrong. Please try again.';
							}

							enableSubmit(form, true);
							errorHandler(error);
						});
					} else {
						/* Otherwise, submit the form and deliver the file regardless of success */
						axios.post(formTarget, formData)
							.catch(errorHandler)
							.finally(() => {
								const destination = form.getAttribute('action');

								/* Open the original URL, respecting the intended target */
								if (form.getAttribute('target') === '_blank') {
									try {
										window.open(destination, '_blank').focus();
									} catch {
										/* If the window failed to open, fall back to opening in the same window */
										document.location.href = destination;
									}
								} else {
									document.location.href = destination;
								}
							});
					}
				}).catch(errorHandler);
			});

			return false;
		});
	});
};

export default {
	setUpFormResponse,
};
